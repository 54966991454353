import React from 'react';

const Services = () => (
    <section className="section" id="services">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                    <div className="text-center mb-lg-5 mb-4">
                        <h3>
                            Our <span className="text-primary">Services</span>
                        </h3>
                        <p className="text-muted">
                            We offer a wide range of software development services to help you achieve your business
                            goals with innovative technology solutions.
                        </p>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 fadeInUp" style={{visibility: "visible", animationDelay: "0.1s"}}>
                    <div className="service-box mt-4">
                        <div className="mb-4">
                            <i className="pe-7s-headphones service-icon"></i>
                        </div>
                        <div className="service-content">
                            <h5>Custom Software Development</h5>
                            <p className="mb-4">
                                Tailored software solutions to meet your unique business requirements, ensuring
                                efficiency and scalability.
                            </p>
                            <a href="#">
                                Read more <span className="right-icon">&#8594;</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="service-box mt-4">
                        <div className="mb-4">
                            <i className="pe-7s-tools service-icon"></i>
                        </div>
                        <div className="service-content">
                            <h5>Web Development</h5>
                            <p className="mb-4">
                                Creating responsive and robust web applications using the latest technologies to enhance
                                your online presence.
                            </p>
                            <a href="#">
                                Read more <span className="right-icon">&#8594;</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="service-box mt-4">
                        <div className="mb-4">
                            <i className="pe-7s-display1 service-icon"></i>
                        </div>
                        <div className="service-content">
                            <h5>Mobile App Development</h5>
                            <p className="mb-4">
                                Developing high-performance mobile applications for iOS and Android to give your
                                business a competitive edge.
                            </p>
                            <a href="#">
                                Read more <span className="right-icon">&#8594;</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="service-box mt-4">
                        <div className="mb-4">
                            <i className="pe-7s-cup service-icon"></i>
                        </div>
                        <div className="service-content">
                            <h5>Cloud Solutions</h5>
                            <p className="mb-4">
                                Implementing scalable cloud infrastructure to enhance your business operations and data
                                security.
                            </p>
                            <a href="#">
                                Read more <span className="right-icon">&#8594;</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="service-box mt-4">
                        <div className="mb-4">
                            <i className="pe-7s-light service-icon"></i>
                        </div>
                        <div className="service-content">
                            <h5>UI/UX Design</h5>
                            <p className="mb-4">
                                Designing intuitive and engaging user interfaces to enhance user experience and drive
                                engagement.
                            </p>
                            <a href="#">
                                Read more <span className="right-icon">&#8594;</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="service-box mt-4">
                        <div className="mb-4">
                            <i className="pe-7s-graph1 service-icon"></i>
                        </div>
                        <div className="service-content">
                            <h5>Data Analytics</h5>
                            <p className="mb-4">
                                Leveraging data analytics to provide actionable insights and drive informed business
                                decisions.
                            </p>
                            <a href="#">
                                Read more <span className="right-icon">&#8594;</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

);

export default Services;
