import React, { useState } from 'react';
import axios from 'axios';


const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        MSISDN: '',
        message: '',
    });

    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const fadeIn = (elementId) => {
        const fade = document.getElementById(elementId);
        if (fade) {
            let opacity = 0;
            const intervalID = setInterval(function () {
                if (opacity < 1) {
                    opacity += 0.5;
                    fade.style.opacity = opacity;
                } else {
                    clearInterval(intervalID);
                }
            }, 200);
        }
    };


    const validateForm = () => {
        const { name, email, MSISDN, message } = formData;

        if (!name) {
            setErrorMsg('Please enter your name.');
            fadeIn();
            return false;
        }

        if (!email) {
            setErrorMsg('Please enter your email.');
            fadeIn();
            return false;
        }

        if (!MSISDN) {
            setErrorMsg('Please enter the Mobile Number.');
            fadeIn();
            return false;
        }

        if (!message) {
            setErrorMsg('Please enter your message.');
            fadeIn();
            return false;
        }

        setErrorMsg('');
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await axios.post('https://api.twalitso.com/contactus/', formData);
                console.log('Response:', response.data);
                setSuccessMsg(response.data.message); // Set success message

                // Optionally, reset form data after successful submission
                setFormData({
                    name: '',
                    email: '',
                    MSISDN: '',
                    message: '',
                });
            } catch (error) {
                console.error('Error:', error.response ? error.response.data : error.message);
                // Handle error response, if needed
            }
        }
    };

    return (
        <section className="section" id="contact">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8">
                        <div className="text-center mb-5">
                            <h3>Get In <span className="text-primary">Touch</span></h3>
                            <p className="text-muted">Tell us about your project</p>
                        </div>
                    </div>
                </div>

                <div className="">
                    {/*<div className="col-lg-4">
                        <div className="Contact-info d-flex align-items-center">
                            <div className="icon flex-shrink-0">
                                <i className="mdi mdi-map-marker"></i>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <p className="text-muted mb-0">Unit 4, Crooks Ind Est, Croft St, Cheltenham, GL53
                                    0ED.</p>
                            </div>
                        </div>
                        <div className="Contact-info d-flex align-items-center mt-3">
                            <div className="icon flex-shrink-0">
                                <i className="mdi mdi-phone"></i>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <p className="text-muted mb-0">+250 4512 2165</p>
                            </div>
                        </div>
                        <div className="Contact-info d-flex align-items-center mt-4">
                            <div className="icon flex-shrink-0">
                                <i className="mdi mdi-email"></i>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <p className="text-muted mb-0">support@website.com</p>
                            </div>
                        </div>
                        <div className="Contact-info d-flex align-items-center mt-4">
                            <div className="icon flex-shrink-0">
                                <i className="mdi mdi-clock-time-four-outline"></i>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <p className="text-muted mb-0">Mon - Sat 9:00 am - 6:30 pm</p>
                            </div>
                        </div>
                    </div>*/}

                    <div className="col-lg-10 offset-lg-1">
                        <div className="mt-4 mt-lg-0">
                            <form onSubmit={handleSubmit}>
                                {errorMsg && <div id="error-msg" className="alert alert-danger error_message">{errorMsg}</div>}
                                {successMsg && <div className="alert alert-success">{successMsg}</div>}

                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input
                                                name="name"
                                                id="name"
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter your name.."
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email address</label>
                                            <input
                                                name="email"
                                                id="email"
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter your email.."
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="subject" className="form-label">Mobile Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="MSISDN"
                                                name="MSISDN"
                                                placeholder="Enter Mobile Number.."
                                                value={formData.MSISDN}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="comments" className="form-label">Message</label>
                                            <textarea
                                                name="message"
                                                id="message"
                                                rows="3"
                                                className="form-control"
                                                placeholder="Enter message.."
                                                value={formData.message}
                                                onChange={handleChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button type="submit" id="submit" name="send" className="btn btn-primary">
                                            Send Message<i className="mdi mdi-telegram ms-2"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
