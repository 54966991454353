import React from 'react';
import feature_1 from '../assets/images/features/img-1.png';
import feature_2 from '../assets/images/features/img-2.png';
import feature_3 from '../assets/images/features/img-3.png';
import feature_bg from '../assets/images/features/features-bg.png';



const Features = () => (
    <section className="section" id="features">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                    <div className="text-center mb-5">
                        <h3>
                            Key <span className="text-primary">Capabilities</span>
                        </h3>
                        <p className="text-muted">
                            Explore the core features and capabilities that set our software development services apart.
                        </p>
                    </div>
                </div>
            </div>

            <div className="row align-items-center">
                {/* Feature 1 */}
                <div className="col-lg-5 col-md-6">
                    <div className="text-center mb-4 mb-lg-0">
                        <img src={feature_1} alt="Feature" className="img-fluid position-relative"/>
                        <div className="position-absolute top-0 start-0" style={{zIndex: -1}}>
                            <img src={feature_bg} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 offset-lg-1">
                    <div className="features-box">
                        <div className="features-icon mb-4">
                            <i className="mdi mdi-tools"></i>
                        </div>
                        <h5 className="mb-3">Scalable Solutions</h5>
                        <div className="d-flex">
                            <i className="mdi mdi-check-bold align text-primary me-2"></i>
                            <p className="text-muted mb-2">
                                Our software solutions are designed to scale seamlessly with your business as it grows.
                            </p>
                        </div>
                        <div className="d-flex">
                            <i className="mdi mdi-check-bold align text-primary me-2"></i>
                            <p className="text-muted mb-3">
                                From small startups to large enterprises, we have the flexibility to meet your needs.
                            </p>
                        </div>
                        <a href="#" className="text-primary">
                            Learn More <span className="right-icon ms-2">&#8594;</span>
                        </a>
                    </div>
                </div>
                {/* End Feature 1 */}

                {/* Feature 2 */}
                <div className="col-lg-5 col-md-6 order-lg-last">
                    <div className="text-center mb-4 mb-lg-0">
                        <img src={feature_2} alt="Feature" className="img-fluid position-relative"/>
                        <div className="position-absolute top-0 start-0" style={{zIndex: -1}}>
                            <img src={feature_bg} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 offset-lg-1">
                    <div className="features-box">
                        <div className="features-icon mb-4">
                            <i className="mdi mdi-code-braces"></i>
                        </div>
                        <h5 className="mb-3">Custom Development</h5>
                        <div className="d-flex">
                            <i className="mdi mdi-check-bold align text-primary me-2"></i>
                            <p className="text-muted mb-2">
                                Tailor-made solutions crafted to match your unique business requirements and objectives.
                            </p>
                        </div>
                        <div className="d-flex">
                            <i className="mdi mdi-check-bold align text-primary me-2"></i>
                            <p className="text-muted mb-3">
                                Our expert developers leverage cutting-edge technologies to build custom software that
                                drives innovation and growth.
                            </p>
                        </div>
                        <a href="#" className="text-primary">
                            Learn More <span className="right-icon ms-2">&#8594;</span>
                        </a>
                    </div>
                </div>
                {/* End Feature 2 */}
            </div>
            {/* End row */}

            {/* Feature 3 */}
            <div className="row align-items-center mt-5">
                <div className="col-lg-5 col-md-6">
                    <div className="text-center mb-4 mb-lg-0">
                        <img src={feature_3} alt="Feature" className="img-fluid position-relative"/>
                        <div className="position-absolute top-0 start-0" style={{zIndex: -1}}>
                            <img src={feature_bg} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 offset-lg-1">
                    <div className="features-box">
                        <div className="features-icon mb-4">
                            <i className="mdi mdi-security"></i>
                        </div>
                        <h5 className="mb-3">Robust Security</h5>
                        <div className="d-flex">
                            <i className="mdi mdi-check-bold align text-primary me-2"></i>
                            <p className="text-muted mb-2">
                                We prioritize the security of your applications and data with state-of-the-art security
                                measures.
                            </p>
                        </div>
                        <div className="d-flex">
                            <i className="mdi mdi-check-bold align text-primary me-2"></i>
                            <p className="text-muted mb-3">
                                Our security experts ensure compliance with industry standards and protect against
                                potential threats.
                            </p>
                        </div>
                        <a href="#" className="text-primary">
                            Learn More <span className="right-icon ms-2">&#8594;</span>
                        </a>
                    </div>
                </div>
            </div>
            {/* End Feature 3 */}
        </div>
        {/* End container */}
    </section>


);

export default Features;
