import React from 'react';

const Footer = () => (
    <>
        <section className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="mb-4">
                            <a href="index-1.html"><img src="assets/images/logo-dark.png" alt="" className="logo-dark"
                                                        height="24"/></a>
                            <p className="text-muted mt-4 mb-2 me-lg-4">
                                We are dedicated to crafting innovative software solutions that empower businesses and
                                individuals to thrive in the digital age. Our team of experts is committed to delivering
                                cutting-edge technology tailored to your specific needs.

                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-6">
                        <h6 className="mb-3">Services</h6>
                        <ul className="list-unstyled company-sub-menu">
                            <li><a href="#">Custom Software Development</a></li>
                            <li><a href="#">Web Development</a></li>
                            <li><a href="#">Mobile App Development</a></li>
                            <li><a href="#">UI/UX Design</a></li>
                            <li><a href="#">Quality Assurance</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-2 col-6">
                        <h6 className="mb-3">About Us</h6>
                        <ul className="list-unstyled company-sub-menu">
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">FAQs</a></li>
                            <li><a href="#">Blog</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <h6 className="mb-3">Company</h6>
	                        <div className="Contact-info d-flex align-items-center mt-4">
	                            <div className="icon flex-shrink-0">
	                                <i className="mdi mdi-email"></i>
	                            </div>
	                            <div className="flex-grow-1 ms-3">
	                                <p className="text-muted mb-0">twalitsotech@gmail.com</p>
	                            </div>
	                        </div>

	                        <div className="Contact-info d-flex align-items-center mt-3">
	                            <div className="icon flex-shrink-0">
	                                <i className="mdi mdi-phone"></i>
	                            </div>
	                            <div className="flex-grow-1 ms-3">
	                                <p className="text-muted mb-0">+260 974 000696/+260 971 025304</p>
	                            </div>
	                        </div>
                        <ul className="list-inline footer-social-list mt-4">
                            <li className="list-inline-item"><a href="#"><i className="mdi mdi-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#"><i className="mdi mdi-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#"><i className="mdi mdi-linkedin"></i></a></li>
                            <li className="list-inline-item"><a href="#"><i className="mdi mdi-skype"></i></a></li>
                            <li className="list-inline-item"><a href="#"><i className="mdi mdi-slack"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <div className="footer-alt py-4 bg-dark">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center text-muted">
                            <p className="mb-0 f-15">
                                {new Date().getFullYear()} &copy; Twalitso Innovations
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default Footer;
