import React from 'react';
import about_us from '../assets/images/features/presentation2.svg';
import feature_2 from "../assets/images/features/img-2.png";

const About = () => (
    <section className="section bg-light" id="about">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-8">
                    <div className="mt-4 mt-lg-0">
                        <h6 className="text-uppercase mb-3">About Us</h6>
                        <h3>
                            Discover More About <span className="text-primary">Our Company</span>
                        </h3>
                        <p className="text-muted mb-3">
                            We are a leading software development company dedicated to delivering innovative technology
                            solutions. Our team of experts works tirelessly to create custom software that meets your
                            unique business needs.
                        </p>
                        <ul className="list-unstyled text-muted">
                            <li>
                                <i className="mdi mdi-check-bold align text-primary me-2"></i> Expert team of software
                                developers and engineers.
                            </li>
                            <li className="my-2">
                                <i className="mdi mdi-check-bold align text-primary me-2"></i> Custom solutions tailored
                                to your business needs.
                            </li>
                            <li>
                                <i className="mdi mdi-check-bold align text-primary me-2"></i> Commitment to innovation
                                and excellence.
                            </li>
                        </ul>
                        <div className="mt-4">
                            <a href="#" className="btn btn-primary">
                                Learn More <span className="right-icon">&#8594;</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="mt-4 mt-lg-0">
                        <img src={about_us} alt="About Us" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    </section>

);

export default About;
