import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@mdi/font/css/materialdesignicons.min.css';
import './assets//css/pe-icon-7.css';
import './assets//css/style.min.css';
import NavBar from './NavBar';
import Home from './sections/Home';
import Services from './sections/Services';
import About from './sections/About';
import Features from './sections/Features';
import Projects from './sections/Projects';
import Testimonial from './sections/Testimonial';
import Contact from './sections/Contact';
import Footer from './Footer';

const ScrollSticky = () => {
    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.getElementById("navbar");
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop >= 50) {
                navbar.classList.add("nav-sticky");
            } else {
                navbar.classList.remove("nav-sticky");
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return null;
};

const Preloader = () => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 350);
        return () => clearTimeout(timer);
    }, []);

    return visible ? (
        <div id="preloader">
            <div id="status">
                <div className="spinner">
                    <div className="dot1"></div>
                    <div className="dot2"></div>
                </div>
            </div>
        </div>
    ) : null;
};

const Counter = () => {
    // Implement Counter component logic here
    return null;
};

const ScrollTopButton = () => {
    const handleScroll = () => {
        const mybutton = document.getElementById("back-to-top");
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        mybutton.style.display = scrollTop > 100 ? "block" : "none";
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <button id="back-to-top" onClick={scrollToTop}><i className="mdi mdi-arrow-up-bold"></i></button>
    );
};

function App() {
    return (
        <div>
            <ScrollSticky/>
            <Preloader/>
            <Counter/>
            <NavBar/>
            <Home/>
            <Services/>
            <About/>
            <Features/>
            <Projects/>
            {/*<Testimonial/>*/}
            <Contact/>
            <Footer/>
            <ScrollTopButton/>

        </div>
    );
}

export default App;
