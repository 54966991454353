import React from 'react';
import homeImg from '../assets/images/home/home1.png';
import ShapeComponent from "../component/ShapeComponent";


const Home = () => (
    <div>
        <section className="bg-home" id="home">
            <div className="home-center">
                <div className="home-desc-center">
                    <div className="container">
                        <div className="position-relative" style={{zIndex: 1}}>
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="pe-lg-4">
                                        <h6 className="text-uppercase text-secondary f-14 mb-4 fw-bold">Innovative
                                            Software
                                            Solutions</h6>
                                        <h2 className="mb-4">
                                            Transform Your Business with <span className="text-secondary fw-medium">Our Software Development Expertise</span>
                                        </h2>
                                        <p className="text-muted fs-17 mb-4 pb-2">
                                            We provide cutting-edge software development services to help you achieve
                                            your
                                            business goals. From concept to deployment, our team of experts will deliver
                                            high-quality solutions tailored to your needs.
                                        </p>
                                        <a href="#" className="btn btn-primary">
                                            Discover Our Services <span className="ms-2 right-icon">&#8594;</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-6 offset-lg-1">
                                    <div className="mt-5 mt-lg-0 text-center">
                                        <img src={homeImg} alt="Home" className="img-fluid mx-auto"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <div className="position-relative">

            <ShapeComponent/>
        </div>

    </div>
);

export default Home;
