import React from 'react';
import logoLight from './assets/images/logo.png';
import logoDark from './assets/images/logo.png';

const NavBar = () => {
    const handleClick = (e) => {
        e.preventDefault();
        const targetId = e.currentTarget.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <nav id="navbar" className="navbar navbar-expand-lg fixed-top sticky">
            <div className="container">
                <a className="navbar-brand" href="index-1.html">
                    <img src={logoLight} alt="logo" height="50" className="logo-light"/>
                    <img src={logoDark} alt="logo" height="50" className="logo-dark"/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="mdi mdi-menu"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul id="navbar-navlist" className="navbar-nav ms-auto">
                        <li className="nav-item"><a href="#home" className="nav-link" onClick={handleClick}>Home</a>
                        </li>
                        <li className="nav-item"><a href="#services" className="nav-link"
                                                    onClick={handleClick}>Services</a></li>
                        <li className="nav-item"><a href="#about" className="nav-link" onClick={handleClick}>About</a>
                        </li>
                        <li className="nav-item"><a href="#features" className="nav-link"
                                                    onClick={handleClick}>Features</a></li>
                        <li className="nav-item"><a href="#projects" className="nav-link" onClick={handleClick}>Projects</a>
                        </li>
                        {/*<li className="nav-item"><a href="#testimonial" className="nav-link" onClick={handleClick}>Projects</a>
                        </li>*/}
                        <li className="nav-item"><a href="#contact" className="nav-link"
                                                    onClick={handleClick}>Contact</a></li>
                    </ul>
                    {/*<div className="phone-call ms-2 mb-3 mb-lg-0">
                        <a href="#" className="nav-link"><i className="mdi mdi-phone me-1"></i> +260978001021</a>
                    </div>*/}
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
