import React from 'react';

const ShapeComponent = () => (
        <div className="shape overflow-hidden text-white position-absolute">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{ width: '100%' }}
                height="250"
                preserveAspectRatio="none"
                viewBox="0 0 1440 250"
            >
                <g mask="url(#SvgjsMask1006)" fill="none">
                    <path
                        d="M 0,246 C 288,210 1152,102 1440,66L1440 250L0 250z"
                        fill="rgba(255, 255, 255, 1)"
                    />
                </g>
                <defs>
                    <mask id="SvgjsMask1006">
                        <rect width="1440" height="250" fill="#ffffff" />
                    </mask>
                </defs>
            </svg>
        </div>

);

export default ShapeComponent;
