import React from 'react';

const ProjectSection = () => {
    const projects = [
       /* {
            imgSrc: 'images/blog-01.jpg',
            category: 'Business',
            date: '24th June 2020',
            author: 'By Admin',
            title: 'Smartest Applications for Business',
            link: '#',
        },
        {
            imgSrc: 'images/blog-02.jpg',
            category: 'Business',
            date: '24th June 2020',
            author: 'By Admin',
            title: 'Winged moved stars, fruit creature seed night.',
            link: '#',
        },
        {
            imgSrc: 'images/blog-03.jpg',
            category: 'Business',
            date: '24th June 2020',
            author: 'By Admin',
            title: 'We Deliver Dreams that you Matches Aspirations',
            link: '#',
        },*/
    ];

    return (
        <section className="section bg-light" id="projects">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="text-center mb-5">
                            <h3>Our <span className="text-primary">Projects</span></h3>
                            <p className="text-muted mb-0">Explore the innovative projects we have worked on to help our clients achieve their goals.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {projects.map((project, index) => (
                        <div className="col-lg-4" key={index}>
                            <div className="blog-box card mt-4">
                                <div className="blog-img">
                                    <img src={project.imgSrc} alt="Project" className="img-fluid rounded-top" />
                                    <div className="blog-tag">
                                        <span>{project.category}</span>
                                    </div>
                                </div>
                                <div className="blog-content card-body p-4">
                                    <ul className="list-inline text-muted mb-0">
                                        <li className="list-inline-item fs-14"><i className="mdi mdi-calendar-month me-1"></i>{project.date}</li>
                                        <li className="list-inline-item fs-14"><i className="mdi mdi-account me-1"></i><a href="#" className="text-muted">{project.author}</a></li>
                                    </ul>
                                    <a href={project.link} className="primary-link">
                                        <h5 className="my-3">{project.title}</h5>
                                    </a>
                                    <a href={project.link} className="text-secondary">Read More <i className="mdi mdi-arrow-right align-middle"></i></a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ProjectSection;
